import React from 'react';
import { ArrowBack, Close } from '@material-ui/icons';

import { findPrevStep } from '../../utils';

const ProgressBar = ({ modalStep, setModalStep, setIsModalOpen }) => {
  const getBackButtonClass = () =>
    (findPrevStep(modalStep)) ? 'modal-icon' : 'hidden'; // prettier-ignore

  const goToPrevStep = (currentStep) => setModalStep(findPrevStep(currentStep));

  const renderBackButton = () => <ArrowBack className={getBackButtonClass()} onClick={() => goToPrevStep(modalStep)} />;

  const renderCloseButton = () => <Close className='modal-icon' onClick={() => setIsModalOpen(false)} />;

  const renderProgressAnimation = () => <div className={`progress-${modalStep}`} />;

  return (
    <div>
      <div className='modal-top'>
        {renderBackButton()}
        {renderCloseButton()}
      </div>
      {renderProgressAnimation()}
    </div>
  );
};

export default ProgressBar;
