import React from 'react';

import QuoteButton from '../../Shared/QuoteButton';
import { closingTitle, altCtaLine1 } from '../../../../customization/_import';

import { renderAltCtaLine2, renderAltCtaHref } from '../../../utils';

const CTA = ({ setIsModalOpen }) => {
  return (
    <section className='cta'>
      <h1>{closingTitle}</h1>
      <div className='cta-quote'>
        <QuoteButton onClick={setIsModalOpen} />
      </div>
      <div className='call-text'>
        <a href={renderAltCtaHref()}>
          <div className='alt-cta-text'>{altCtaLine1}</div>
          <div className='alt-cta-phone'>{renderAltCtaLine2()}</div>
        </a>
      </div>
    </section>
  );
};

export default CTA;
