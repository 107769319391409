import React, { useState, useEffect } from 'react';
import moment from 'moment';

import QuoteModal from './Modal/QuoteModal';
import LandingPage from './LandingPage/LandingPage';
import { pageTitle } from '../customization/_import';
import RedirectLander from './RedirectLander/RedirectLander';
import { getIdentifier, initializeLogRocket } from './utils';

const PageContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [identifier, setIdentifier] = useState();
  const [initialLoading, setInitialLoading] = useState(true);
  const [modalStep, setModalStep] = useState('zip');
  // Holding state of user information collected in the 'Get Quote' modal - if user closes modal and reopens it, their information and step is saved
  const [consumerDetails, setConsumerDetails] = useState({
    destinationZip: '',
    originZip: '',
    beds: '',
    givenName: '',
    familyName: '',
    email: '',
    phone: '',
    // Selected country code gets updated when user enters their phone number
    countryCode: '',
    // Default move date set to today
    moveDate: moment(),
  });

  useEffect(() => {
    setIdentifier(getIdentifier());
    initializeLogRocket();
    preloadImages();
    setInitialLoading(false);
  }, []);

  document.title = pageTitle;

  const preloadImages = () => {
    const startRecordingImage = new Image(); // image used on the Start Recording page of the modal. Image takes a second to load - preloading here so its ready when user gets to it in the modal
    startRecordingImage.src = 'https://media.yembo.ai/mariner/lander/demo.gif';
  };

  const renderQuoteModal = () => (
    <QuoteModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      modalStep={modalStep}
      setModalStep={setModalStep}
      consumerDetails={consumerDetails}
      setConsumerDetails={setConsumerDetails}
    />
  );

  const renderLandingPage = () => {
    if (!initialLoading) {
      if (identifier) {
        return <RedirectLander identifier={identifier} />;
      }
      return <LandingPage isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />;
    }
  };

  return (
    <div>
      {renderQuoteModal()}
      {renderLandingPage()}
    </div>
  );
};

export default PageContainer;
