import React from 'react';

import { ReactComponent as Fast } from '../../../../assets/our-process/faster.svg';
import { ReactComponent as Easy } from '../../../../assets/our-process/easier.svg';
import { ReactComponent as Cheap } from '../../../../assets/our-process/cheaper.svg';

import {
  highlightTitle1,
  highlightSubtitle1,
  highlightTitle2,
  highlightSubtitle2,
  highlightTitle3,
  highlightSubtitle3,
} from '../../../../customization/_import';

const Hightlights = () => {
  const highlights = [
    {
      image: <Fast className='highlights-svg' />,
      title: highlightTitle1,
      description: highlightSubtitle1,
    },
    {
      image: <Easy className='highlights-svg' />,
      title: highlightTitle2,
      description: highlightSubtitle2,
    },
    {
      image: <Cheap className='highlights-svg' />,
      title: highlightTitle3,
      description: highlightSubtitle3,
    },
  ];

  const renderHighlightImage = (image) => image;

  const renderHighlightTitle = (title) => <h3>{title}</h3>;

  const renderHightlightDescription = (description) => <p>{description}</p>;

  return (
    <div className='highlights'>
      {highlights.map((item, index) => {
        const { image, title, description } = item;
        return (
          <div className='indiv-highlight' key={`highlights-${index}`}>
            {renderHighlightImage(image)}
            {renderHighlightTitle(title)}
            {renderHightlightDescription(description)}
          </div>
        );
      })}
    </div>
  );
};

export default Hightlights;
