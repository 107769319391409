import company from './util/company.json';
import apiEnv from './util/apiEnv.json';

import { verifyApiEnv } from './util/verifyApiEnv';
import { getLocalJs } from './util/getLocalJs';

import accurate from '../assets/our-process/accurate.png';
import instantaneous from '../assets/our-process/instantaneous.png';
import video from '../assets/our-process/video.png';
import ourProcess from '../assets/our-process/our-process.jpg';

import calendar from '../assets/other-movers/calendar.png';
import clock from '../assets/other-movers/clock.png';
import inaccurate from '../assets/other-movers/inaccurate.png';
import sad from '../assets/other-movers/sad.png';
import otherMovers from '../assets/other-movers/other-movers.jpg';

import none from '../assets/summary-table/none.png';
import testimonial from '../assets/testimonial/testimonial.jpg';

import step1 from '../assets/steps/step1.png';
import step2 from '../assets/steps/step2.png';
import step3 from '../assets/steps/step3.png';

verifyApiEnv();

const { name, branch: companyBranch, defaultCountry: companyDefaultCountry, domain } = company;

const localJs = getLocalJs(companyBranch);

export const combinedCustomization = { ...company, ...apiEnv, ...localJs };

const getImg = (imgName, defaultImg = null) => {
  try {
    return require(`../../public/img/s3/${combinedCustomization[imgName]}`); // checks if image name from S3 is in local combined customizations file
  } catch (e) {
    return defaultImg; // if not, use the default Img
  }
};

const getComponent = (key) => {
  try {
    if (localJs[key]) {
      const componentName = localJs[key];
      return `/branches/${process.env.REACT_APP_API_ENV}/${branch}/${componentName}`;
    }
    return null;
  } catch (e) {
    return null;
  }
};

// must specify which values you're using from the combined config.
// this way we always have a list of which ones are being used
export const {
  companyName = name,
  altCtaPhoneE164,
  branch = companyBranch,
  defaultCountry = companyDefaultCountry || 'US',
  frontendUrl = `${domain}/sign-up`,

  privacyPolicyUrl = 'https://yembo.ai/legal/consumer/privacy-policy',

  altCtaHref = null,
  altCtaLine1 = "I'd rather talk about it over the phone: ",
  altCtaLine2 = null,
  showPhoneIconMobile = true,
  reviewsUrl = null,
  headerTitle = 'The Quickest Moving Quote Possible!',
  headerSubtitle = "To get a quote, record a video of your home on your phone. That's it!",
  headerSubtitle2 = null,
  headerSubtitle3 = null,
  ctaText = null,
  highlightTitle1 = 'Faster',
  highlightSubtitle1 = 'There’s no need to take a day off work or sit through an hour-long video call. Just record a quick video of your home. We do the rest.',
  highlightTitle2 = 'Easier',
  highlightSubtitle2 = 'Instead of a blanket price, you get an itemized quote based on your exact inventory.',
  highlightTitle3 = 'Save Money',
  highlightSubtitle3 = 'Is the quote too high? Just remove a few items to bring the quote down.',
  reviewQuote = null,
  reviewCaption = null,
  ratingCount = null,
  reviewStarsNumber = null,
  reviewStarsTotal = 5,
  reviewText = null,
  moreReviews = 'Read more reviews',
  testimonialSrc = getImg('image_backgroundTestimonial', testimonial),
  closingTitle = "Get your no obligation quote now! It's fast and easy!",
  tagline = 'The most relaxing moving experience.',
  certifications = null,
  companyLegalName = name,
  pageTitle = name,
  showOtherMoversSection = false,
  showHighlights = true,
  showSummaryTable = true,
  process1Text = 'Immediate and on your own time. Instantaneous!',
  process1Icon = getImg('image_instantaneousIcon', instantaneous),
  process2Text = 'Takes only minutes for the most accurate quote.',
  process2Icon = getImg('image_accurateIcon', accurate),
  process3Text = 'Our software uses your video to create a very accurate quote.',
  process3Icon = getImg('image_videoIcon', video),
  ourProcessSrc = getImg('image_backgroundCompare', ourProcess),
  otherMovers1Text = 'Schedule an on-site survey, which can take days or weeks.',
  otherMovers1Icon = getImg('image_calendarIcon', calendar),
  otherMovers2Text = 'On-site surveys are inconvenient and take hours of your time.',
  otherMovers2Icon = getImg('image_clockIcon', clock),
  otherMovers3Text = 'Provide inaccurate quotes on the phone leading to last-minute stress.',
  otherMovers3Icon = getImg('image_inaccurateIcon', inaccurate),
  otherMoversSrcMobile = getImg('image_otherMovers', otherMovers),
  sadImgSrc = getImg('image_sadIcon', sad),
  stepsTitle = 'Get started in 3 easy steps',
  step1Text = 'Walk around your home recording a video of all your belongings.',
  step2Text = 'Our software scans the video and creates an itemized inventory.',
  step3Text = 'Get your quote!',
  step1ImageSrc = getImg('image_backgroundStep1', step1),
  step2ImageSrc = getImg('image_backgroundStep2', step2),
  step3ImageSrc = getImg('image_backgroundStep3', step3),
  headerVideoSrc = 'https://media.yembo.ai/mariner/lander/lander-background.mp4',
  heroImage = getImg('image_backgroundHero', null),
  logoHeader = getImg('image_logo'),
  logoHeaderRedirect = getImg('image_logoRedirect'),
  logoFooter = getImg('image_logoFooter') || getImg('image_logo'),
  tableNoneIcon = getImg('image_noneIcon', none),
  tableCheckIcon = getImg('image_checkmark'),
  tableHeaderOurWay = 'Our New Moving Quote',
  tableHeaderOldWay = 'Old-Fashioned Moving Quote',
  listTitleText = 'Our Super Fast Process',
  experienceSmileysTag = 'Your Experience',
  rowName1 = 'Fast Quotes',
  rowName2 = 'Done on a smartphone',
  rowName3 = 'Painless process',
  rowName4 = 'Most affordable option',
  rowName5 = null,
  continueButtonText = 'Continue',
  movingFromLabel = null,
  movingToLabel = null,
  expectedMoveDateLabel = 'Expected move date',
  bedroomsLabel = 'How many bedrooms?',
  contactFormLabel = 'Where should we send your quote?',
  firstNameLabel = 'First Name',
  lastNameLabel = 'Last Name',
  emailLabel = 'Email',
  securityText = 'We respect your privacy. Your information is shared securely.',
  consent = null,
  videoTipsHeader = 'An accurate quote is a few quick videos away!',
  videoTipsSubHeader = 'Take a few short videos of the rooms in your house, and we will provide an accurate quote with no hidden fees.',
  videoTipsStep1Text = 'Record rooms with items to move',
  videoTipsStep2Text = 'Our software creates moving list for you',
  videoTipsStep3Text = 'You get an accurate quote based on your items',
  videoTipsStep4Text = null,
  inviteButtonText = 'Start Recording',
  loading = 'Loading...',
  reminderButtonText = 'Not Home?',
  startRecordingSMS = 'Check your text messages',
  startRecordingEmail = 'Check your email',
  startRecordingText1 = 'We sent you a link so you can record a few quick videos of your rooms. Please record on a mobile device.',
  startRecordingText2 = 'We will get back to you shortly to discuss your move.',
  recordingSubtitle = 'Choose a time that you are available to record your belongings. We will email you a link with instructions at that time.',
  backButton = 'Go back',
  reminderConfirm = 'Remind Me Then',
  reminderSuccess = 'A link to record your items will be sent at this time:',
  reminderSuccess2 = null,
  certificationImg = getImg('image_copyright'), // getting image by the old name 'image_copyright' but this image actually goes next to the certications section, not copyright
  isPhoneRequired = false,
  termsOfUse = null,
  privacyPolicy = 'Privacy Policy',
  showTestimonialSection = true,
  errorMoveDate = 'Please select a date in the future',
  errorPostCode = null,
  errorEmail = 'Please enter a valid email',
  errorPhone = 'Please enter a valid phone',
  errorBeds = 'Please choose number of bedrooms',
  errorRequired = 'Field required',
  errorGeneric = 'Uh oh - something went wrong. Refresh screen to try again.',
  badLead = 'Thank you! We will be in touch shortly.',
  reminderError = 'Reminder time must be in the future.',
  errorBackButton = 'Enter New Details',
  testimonialContent = getComponent('component_testimonialContent'), // keeps testimonial image
  testimonialSection = getComponent('component_testimonialSection'), // replaces whole section
  processIcons = getComponent('component_processIcons'),
  certificationComponent = getComponent('component_certification'),
  showLogoDropshadow = false,

  hasCustomScripts = false,

  customIntegrationHeader = null,
  customIntegrationSubHeader = null,
  customIntegrationStepsHeader = "It's easy -",
  customIntegrationFooter = null,

  gtmId,
  s3Bucket,
  apiUrl,
  isDevEnvironment = process.env.REACT_APP_API_ENV.startsWith('dev'),
  isProdEnvironment = process.env.REACT_APP_API_ENV.startsWith('prod'),

  primaryFill,
  secondaryFill,

  logoFavicon = getImg('image_logoFavicon'),
} = combinedCustomization;
