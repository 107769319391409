import axios from 'axios';
import * as moment from 'moment';
import LogRocket from 'logrocket';

import {
  apiUrl,
  defaultCountry,
  isPhoneRequired,
  frontendUrl,
  altCtaPhoneE164,
  altCtaLine2,
  altCtaHref,
  isDevEnvironment,
  errorPostCode,
  errorMoveDate,
  errorRequired,
  errorBeds,
  errorEmail,
  errorPhone,
} from '../customization/_import';

const i18nZipcodes = require('i18n-zipcodes');
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

////------------------------------- General API ------------------------------------------////

export const yemboApiCall = axios.create({
  baseURL: `${apiUrl}/`,
  data: {},
  timeout: 20000,
  headers: { 'content-type': 'application/json' },
});

export const setApiError = (e, setRequestError) => {
  const { response } = e;
  // handles if internet connection fails and there's an error with no response
  const hasErrorMessage = response && response.data; // can't destructure response because it doesn't always exist
  const errorMessage = (hasErrorMessage) ? response.data.status[0].message : null; // prettier-ignore
  const errorType = (hasErrorMessage) ? response.data.status[0].type : null; // prettier-ignore
  setRequestError({ hasError: true, errorMessage, errorType });
};

////------------------------------- Start Recording ------------------------------------------////

export const getIdentifier = () => {
  const href = window.location.href || '';

  const results = href.match(/.*\?identifier=([A-Za-z0-9]+).*/);
  if (results === null) {
    return;
  }

  return results[1];
};

const validatePhone = (data) => {
  return yemboApiCall({ method: 'post', url: 'invite/validate-phone', data });
};

export const createInvite = (data) => {
  return yemboApiCall({ method: 'post', url: 'invite', data });
};

export const redirectMobileInvite = (inviteData) => {
  const { identifier } = inviteData;
  return (window.location.href = `${frontendUrl}?identifier=${identifier}`);
};

export const checkShouldSendSms = async (phone, setCanSendSms) => {
  if (!phone) {
    return setCanSendSms(false);
  }
  const canSendSmsRes = await validatePhone({
    phone,
  });
  const { canSendSms } = canSendSmsRes.data;
  return setCanSendSms(canSendSms);
};

export const handleRecordingSubmit = async ({
  setIsSurveyLoading,
  prepareInviteData,
  successAction,
  setRequestError,
}) => {
  try {
    setIsSurveyLoading(true);
    const inviteData = await prepareInviteData();
    if (isMobileDevice()) {
      return redirectMobileInvite(inviteData);
    }
    await createInvite(inviteData);
    setIsSurveyLoading(false);
    successAction();
  } catch (e) {
    setApiError(e, setRequestError);
  }
};

export const handleReminderSubmit = async ({
  reminderTime,
  setIsReminderLoading,
  prepareInviteData,
  setDidReminderSucceed,
  successAction,
  setRequestError,
}) => {
  try {
    setIsReminderLoading(true);
    const inviteData = await prepareInviteData(reminderTime);
    await createInvite(inviteData);
    setIsReminderLoading(false);
    setDidReminderSucceed(true);
    setTimeout(() => {
      successAction();
      setDidReminderSucceed(false);
    }, 5000);
  } catch (e) {
    setApiError(e, setRequestError);
  }
};

////------------------------------- Quote Modal Step ------------------------------------------////

export const findPrevStep = (currentStep) => {
  switch (currentStep) {
    case 'zip':
      return null;
    case 'beds':
      return 'zip';
    case 'contact':
      return 'beds';
    case 'startRecording':
      return null;
    case 'badLead':
      return null;
    default:
      return null;
  }
};

export const findNextStep = (currentStep) => {
  switch (currentStep) {
    case 'zip':
      return 'beds';
    case 'beds':
      return 'contact';
    case 'contact':
      return 'startRecording';
    default:
      return null;
  }
};

////------------------------------- Quote Modal Form ------------------------------------------////

export const removeNonDigitCharacters = (number) => number.replace(/\D/g, '');

// to use with google's phone utils package
export const getParsedNumber = (phone, countryCode) => {
  return phoneUtil.parseAndKeepRawInput(phone, countryCode);
};

// format numbers in the national format e.g. (202) 456-1414
export const printPhoneNational = (phone, countryCode) => {
  phone = getParsedNumber(phone, countryCode);
  return phoneUtil.format(phone, PNF.NATIONAL);
};

// format phone strips the ( ) and - from the phone number. It only returns the numerical digits and adds the + sign in front e.g. +12024561414
export const printPhoneE164 = (phone, countryCode) => {
  phone = getParsedNumber(phone, countryCode);
  return phoneUtil.format(phone, PNF.E164);
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isValidEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

export const isValidPhone = (phone, region) => {
  const countryCode = (region)
		? region.countryCode // region is from country dropdown in phone field
		: defaultCountry; // prettier-ignore

  try {
    // Allow 555 numbers on dev to pass validation:
    const isTestingOnDev = isDevEnvironment && phone.includes('555');
    if (isTestingOnDev) return true;

    const parsedNumber = getParsedNumber(phone, countryCode);
    return phoneUtil.isValidNumber(parsedNumber);
  } catch (e) {
    return false;
  }
};

export const isValidZipCode = (zipCode) => {
  try {
    switch (defaultCountry) {
      case 'US':
        return i18nZipcodes(defaultCountry.toLowerCase(), zipCode);

      default:
        return true;
    }
  } catch (error) {
    return true;
  }
};

export const getDateFormat = () => {
  const monthFirstCompanies = ['US', 'AU', 'CA']; // manually adding countries that use month first formatting. Problems using external package with google's date picker

  if (monthFirstCompanies.includes(defaultCountry)) {
    return 'MM-DD-YYYY';
  }

  return 'DD-MM-YYYY';
};

export const getZipNoun = () => {
  switch (defaultCountry) {
    case 'US':
      return 'ZIP code';
    case 'NL':
      return 'postal code';
    default:
      return 'post code';
  }
};

export const zipNoun = getZipNoun();

export const checkErrorsOnChange = (name, value, region) => {
  let error = '';

  switch (name) {
    case 'originZip':
    case 'destinationZip':
      if (!isValidZipCode(value)) {
        error = errorPostCode === null ? `Please enter a valid ${zipNoun}` : errorPostCode;
      }
      break;
    case 'moveDate':
      if (moment().diff(value, 'days') > 0) {
        // checks for past date
        error = errorMoveDate;
      }
      break;
    case 'email':
      if (!isValidEmail(value.trim())) {
        error = errorEmail;
      }
      break;
    case 'phone':
      if (!isValidPhone(value, region)) {
        error = errorPhone;
      }
      if (!value && !isPhoneRequired) {
        error = ''; // if user enters a number then deletes it, and phone is not required
      }
      break;
    case 'beds':
      if (!value) {
        error = errorBeds; // to give custom error message if bedrooms is left blank
      }
      break;
    default:
      break;
  }
  return { [name]: error };
};

export const validateFormOnSubmit = (formErrors, formValues, modalStep) => {
  let isValid = true;
  const currentErrors = { ...formErrors };

  // check if form values are empty
  Object.keys(currentErrors[modalStep]).forEach((key) => {
    const fieldNotRequired = key === 'phone' && !isPhoneRequired;

    if (formValues[key].trim && !formValues[key].trim() && !fieldNotRequired) {
      currentErrors[modalStep][key] = errorRequired;
    }
  });

  // check for any other errors exist
  Object.values(currentErrors[modalStep]).forEach((error) => {
    if (error.length > 0) {
      isValid = false;
    }
  });
  return {
    isValid,
    currentErrors,
  };
};

////------------------------------- LogRocket ------------------------------------------////

export const initializeLogRocket = () => {
  const isRunningLocally = window.location.hostname === 'localhost';

  if (!isRunningLocally) {
    LogRocket.init('yembo/service-mariner-lander');
  }
};

export const assignIdLogRocket = (identifier, moveKey, consumer) => {
  const { givenName, familyName, email, phone } = consumer;

  LogRocket.identify(identifier, {
    moveKey,
    name: `${givenName} ${familyName}`,
    email,
    phone,
    givenName,
    familyName,
  });
};

////------------------------------- Landing Page ------------------------------------------////

export const renderAltCtaLine2 = () => {
  if (altCtaLine2) return altCtaLine2;
  if (altCtaPhoneE164) return printPhoneNational(altCtaPhoneE164, defaultCountry);
  return '';
};

export const renderAltCtaHref = () => {
  if (altCtaHref) return altCtaHref;
  if (altCtaPhoneE164) return `tel:${altCtaPhoneE164}`;
  return '';
};
