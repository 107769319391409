import React from 'react';

import StartRecordingSteps from '../StartRecordingSteps/StartRecordingSteps';
import {
  customIntegrationHeader,
  customIntegrationSubHeader,
  customIntegrationFooter,
  videoTipsHeader,
  videoTipsSubHeader,
  inviteButtonText,
  reminderButtonText,
  loading,
} from '../../../../../customization/_import';

const StartRecording = ({ givenName, email, handleStartRecording, handleNotHome, isSurveyLoading, identifier }) => {
  const isIntegratedLander = Boolean(identifier);

  const hasCustomFooter = customIntegrationFooter !== null;

  const renderHeader = () => {
    const showCustomText = isIntegratedLander && Boolean(customIntegrationHeader);
    const text = showCustomText ? customIntegrationHeader : videoTipsHeader;

    const transformFirstLetterLowerCase = (text) => `${text[0].toLowerCase()}${text.slice(1)}`;

    const renderHeaderWithName = (name, text) => {
      if (!name) return text;
      text = transformFirstLetterLowerCase(text);
      return (
        <span>
          <span className='name'>{name}</span>, {text}
        </span>
      );
    };

    return <h3>{renderHeaderWithName(givenName, text)}</h3>;
  };

  const renderSubHeader = () => {
    const showCustomText = isIntegratedLander && Boolean(customIntegrationSubHeader);
    return <p className='sub-head'>{showCustomText ? customIntegrationSubHeader : videoTipsSubHeader}</p>;
  };

  const renderStepsImage = () => (
    <div className='image'>
      <img src='https://media.yembo.ai/mariner/lander/demo.gif' alt='record-video' />
    </div>
  );

  const renderStepsDesktop = () => (
    <div className='step-wrap-desktop'>
      <StartRecordingSteps />
    </div>
  );

  const renderInviteButton = () => (
    <div className='start-recording-button-wrap'>
      {/* button className used for Analytics - beware of changing */}
      <button onClick={handleStartRecording} className='start-recording-button'>
        {(isSurveyLoading) ? loading : inviteButtonText /* prettier-ignore */}
      </button>
    </div>
  );

  const renderReminderButton = () => {
    if (email) {
      return (
        <div className='not-home-button-wrap'>
          {/* button className used for Analytics - beware of changing */}
          <button className='not-home-button' onClick={handleNotHome}>
            {reminderButtonText}
          </button>
        </div>
      );
    }
  };

  const renderStepsMobile = () => (
    <div className='step-wrap-mobile'>
      <StartRecordingSteps />
    </div>
  );

  const renderCustomFooter = () =>
    hasCustomFooter ? <p className='custom-footer'>{customIntegrationFooter}</p> : null;

  return (
    <div className='start-recording-wrap'>
      {renderHeader()}
      {renderSubHeader()}
      <div className='image-step-wrap'>
        <div className='image-wrap'>
          {renderStepsImage()}
          {/* Steps desktop - Steps need to show to the right of the record-video gif on desktop */}
          {renderStepsDesktop()}
        </div>
        {renderInviteButton()}
        {renderReminderButton()}
        {/* Steps mobile - Steps need to show under the action buttons on mobile */}
        {renderStepsMobile()}
      </div>
      {renderCustomFooter()}
    </div>
  );
};

export default StartRecording;
