import React from 'react';
import { Phone } from '@material-ui/icons';

import QuoteButton from '../../Shared/QuoteButton';
import {
  headerVideoSrc,
  logoHeader,
  showPhoneIconMobile,
  headerTitle,
  headerSubtitle,
  headerSubtitle2,
  headerSubtitle3,
  altCtaLine1,
  heroImage,
  showLogoDropshadow,
} from '../../../../customization/_import';
import { renderAltCtaLine2, renderAltCtaHref } from '../../../utils';

const Header = ({ setIsModalOpen }) => {
  const shouldRenderMultipleSubTitle = headerSubtitle2 !== null || headerSubtitle3 !== null;

  const singleSubHead = <p className='sub-head'>{headerSubtitle}</p>;

  const multipleSubHead = (
    <p className='sub-head'>
      <div className='sub-head-line'>{headerSubtitle}</div>
      <div className='sub-head-line'>{headerSubtitle2}</div>
      <div className='sub-head-line'>{headerSubtitle3}</div>
    </p>
  );

  const renderImageBackground = () => (
    <div className='header-background-wrap'>
      <img id='hero-image' className='header-background' src={heroImage} alt='movers' />
    </div>
  );

  const renderVideoBackground = () => (
    <div className='header-background-wrap'>
      <video className='header-background' autoPlay muted loop>
        <source src={headerVideoSrc} type='video/mp4' />
      </video>
    </div>
  );

  const renderBackground = () =>
		(heroImage) ? renderImageBackground() : renderVideoBackground(); // prettier-ignore

  const getLogoClassName = () => (showLogoDropshadow) ? 'logo dropshadow' : 'logo'; // prettier-ignore

  const renderCompanyLogo = () => <img className={getLogoClassName()} src={logoHeader} alt='company-logo' />;

  const renderPhoneIcon = () =>
		(showPhoneIconMobile) ? (
			<a href={renderAltCtaHref()}>
				<Phone className='phone-icon' />
			</a>
		) : null; // prettier-ignore

  const renderHeaderTitle = () => <h1>{headerTitle}</h1>;

  const renderHeaderSubtitles = () => (shouldRenderMultipleSubTitle ? multipleSubHead : singleSubHead);

  const renderGetQuoteButton = () => (
    <div className='header-quote-button'>
      <QuoteButton onClick={setIsModalOpen} />
    </div>
  );

  const renderCallText = () => (
    <div className='desktop-call-text'>
      <a href={renderAltCtaHref()}>
        <p>
          {altCtaLine1}
          {renderAltCtaLine2()}
        </p>
      </a>
    </div>
  );

  const getContentClassName = () =>
		(heroImage) ? 'header-content-wrap' : 'header-content-wrap with-video'; // prettier-ignore

  return (
    <section className='header'>
      {renderBackground()}
      <div className={getContentClassName()}>
        <div className='header-content'>
          <div className='call-section'>
            {renderCompanyLogo()}
            {renderPhoneIcon()}
          </div>
          <div className='header-text'>
            {renderHeaderTitle()}
            {renderHeaderSubtitles()}
            {renderGetQuoteButton()}
            {renderCallText()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
