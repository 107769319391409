import React from 'react';

import Header from './Sections/Header/Header';
import Steps from './Sections/Steps/Steps';
import Process from './Sections/Process/Process';
import Hightlights from './Sections/Highlights/Highlights';
import SummaryTable from './Sections/SummaryTable/SummaryTable';
import Testimonial from './Sections/Testimonial/Testimonial';
import CTA from './Sections/CTA/CTA';
import Footer from './Sections/Footer/Footer';
import QuoteButton from './Shared/QuoteButton';
import OtherMovers from './Sections/OtherMovers/OtherMovers';

import {
  showOtherMoversSection,
  showTestimonialSection,
  showSummaryTable,
  showHighlights,
} from '../../customization/_import';

const LandingPage = (props) => {
  const { isModalOpen, setIsModalOpen } = props;

  const renderHeader = () => <Header setIsModalOpen={() => setIsModalOpen(true)} />;

  // This is the 'Get Quote' button that sticks to the top of the page on mobile. It does not stick on desktop.
  const renderStickyCTA = () => (
    <div className='sticky-cta'>
      <QuoteButton onClick={() => setIsModalOpen(true)} />
    </div>
  );

  const renderSteps = () => <Steps />;

  const renderOtherMovers = () => showOtherMoversSection && <OtherMovers />;

  const renderProcess = () => <Process />;

  const renderHighlights = () => (showHighlights ? <Hightlights /> : null);

  const renderSummaryTable = () => (showSummaryTable ? <SummaryTable /> : null);

  const renderTestimonial = () => showTestimonialSection && <Testimonial />;

  const renderBottomCTA = () => <CTA setIsModalOpen={() => setIsModalOpen(true)} />;

  const renderFooter = () => <Footer />;

  return (
    // Modal takes full screen on mobile. Landing page is hidden on mobile so there's no scroll capability under the modal.
    <div className={`landing-page ${isModalOpen && 'no-display-mobile'}`}>
      {/* sticky-container class enables the 'Get Quote' button to stick to the top of the page on mobile over the sections below */}
      <div className='sticky-container'>
        {renderHeader()}
        {renderStickyCTA()}
        {renderSteps()}
        {renderOtherMovers()}
        {renderProcess()}
        {renderHighlights()}
        {renderSummaryTable()}
        {renderTestimonial()}
      </div>
      {/* 'Get Quote' button no longer sticks to top of the page here since there's also a quote button in the section below */}
      <div className='not-sticky'>
        {renderBottomCTA()}
        {renderFooter()}
      </div>
    </div>
  );
};

export default LandingPage;
