import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-mui';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import { Lock } from '@material-ui/icons';

import {
  validateFormOnSubmit,
  checkErrorsOnChange,
  getDateFormat,
  findNextStep,
  yemboApiCall,
  removeNonDigitCharacters,
  isMobileDevice,
  zipNoun,
} from '../../../utils';
import {
  defaultCountry,
  companyName,
  movingFromLabel,
  movingToLabel,
  expectedMoveDateLabel,
  bedroomsLabel,
  contactFormLabel,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  continueButtonText,
  hasCustomScripts,
  securityText,
  loading,
  consent,
} from '../../../../customization/_import';

const ValidatedForm = (props) => {
  const {
    consumerDetails,
    consumerDetails: { originZip, destinationZip, beds },
    setConsumerDetails,
    modalStep,
    setModalStep,
  } = props;
  const validationData = {
    originZip,
    destinationZip,
    beds,
  };
  const inputRefs = {}; // initializes object. Refs will be created for each input during field map in the return.

  const [formErrors, setFormErrors] = useState({
    // initializes error state
    zip: {
      destinationZip: '',
      originZip: '',
      moveDate: '',
    },
    beds: {
      beds: '',
    },
    contact: {
      givenName: '',
      familyName: '',
      email: '',
      phone: '',
    },
  });
  const [didSubmit, setDidSubmit] = useState(false); // only show error message after they try to submit - otherwise its annoying to the user
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = isMobileDevice();

  const handleInputFocus = (inputName) => {
    if (isMobile) {
      // uses setTimeout so onFocus event fires after keyboard pops up on mobile
      setTimeout(
        () =>
          inputRefs[inputName].current &&
          inputRefs[inputName].current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          }),
        100
      );
    }
  };

  const qualificationCall = (data) => yemboApiCall({ method: 'post', url: 'lander-qualifier', data });

  const handleLeadQualifying = async () => {
    try {
      setIsLoading(true);
      const qualificationRes = await qualificationCall(validationData);
      const { isQualified } = qualificationRes.data;
      setModalStep((isQualified) ? 'startRecording' : 'badLead'); // prettier-ignore
    } catch (e) {
      setModalStep('startRecording'); // if there's an error, assume they are a qualified lead
    }
  };

  const dateHandleChange = (value) =>
    handleChange({
      target: {
        name: 'moveDate',
        value: value || '', // Datepicker will pass null if input is empty
      },
    });

  const phoneHandleChange = (value, region) => {
    handleChange({ target: { name: 'phone', value, region } });
  };

  const phoneOnBlur = (event, region) => {
    const { value } = event.target;
    const { dialCode } = region;
    const isEmpty = value === `+${dialCode}`;

    if (isEmpty) {
      setConsumerDetails({ ...consumerDetails, phone: '' }); // resets to empty value if it's just the dial code. Don't want to submit a phone number that's just the dial code.
    }
  };

  const handleChange = (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }

    // region is only passed on the event in the phone input, otherwise it is undefined
    const { name, value, region } = event.target;

    const newError = checkErrorsOnChange(name, value, region);
    setFormErrors({
      ...formErrors,
      [modalStep]: { ...formErrors[modalStep], ...newError },
    });

    const fieldValue = { [name]: value };
    switch (name) {
      case 'phone':
        fieldValue.countryCode = region.countryCode; // adds selected country code
        break;
      case 'givenName':
      case 'familyName':
        fieldValue[name] = value.charAt(0).toUpperCase() + value.substring(1); // capitalizes first letter in names
        break;
      case 'destinationZip':
      case 'originZip':
        fieldValue[name] = value.trim();
        break;
      default:
        break;
    }

    return setConsumerDetails({ ...consumerDetails, ...fieldValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setDidSubmit(true);

    //validate form checks if fields are empty or if there are errors at any of the fields
    const { isValid, currentErrors } = validateFormOnSubmit(formErrors, consumerDetails, modalStep);

    if (isValid) {
      (modalStep === 'contact')
				? handleLeadQualifying()
				: setModalStep(findNextStep(modalStep)); // prettier-ignore
      setDidSubmit(false);
    } else {
      setFormErrors({ ...currentErrors }); // setsErrors if form values are empty
    }
  };

  const implementCustomScript = (modalStep) => {
    const shouldImplementScript = modalStep === 'contact' && hasCustomScripts && isLoading;
    if (shouldImplementScript) {
      window.ga('send', 'event', {
        eventCategory: 'yembo-form-submitted',
        eventAction: 'user-data-submit',
        eventLabel: 'lead',
      });
    }
  };

  const addBedsActiveClass = (number) =>
		(consumerDetails.beds === number) ? 'active option' : 'option'; // prettier-ignore

  const addDateErrorClass = () => `${didSubmit && formErrors[modalStep]['moveDate'] && 'field-error'}`;

  const addPhoneErrorClass = () =>
    `react-tel-input ${didSubmit && formErrors[modalStep]['phone'] && 'phone-error-container'}`;

  const renderDatePicker = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant='inline'
        format={getDateFormat()}
        value={consumerDetails.moveDate} // this is initalized to today's date
        onChange={dateHandleChange}
        minDateMessage={null} // setting custom error messages
        autoOk
        disablePast
        className={addDateErrorClass()}
        inputProps={{
          onFocus: () => handleInputFocus('moveDate'),
        }}
      />
    </MuiPickersUtilsProvider>
  );

  const renderBedroomBoxes = () => (
    <div className='bedroom-options'>
      {['1', '2', '3', '4', '5+'].map((number) => {
        const numericValue = removeNonDigitCharacters(number);
        return (
          <button
            className={addBedsActiveClass(numericValue)}
            key={number}
            value={numericValue}
            name={'beds'}
            onClick={handleChange}
          >
            {number}
          </button>
        );
      })}
    </div>
  );

  const phoneInputExtraProps = (isMobile) ? { onFocus: () => handleInputFocus('phone') } : {}; // prettier-ignore

  const renderPhoneInput = () => {
    let reactPhoneInputDefaultCountry;

    switch (defaultCountry) {
      case 'UK':
        reactPhoneInputDefaultCountry = 'gb';
        break;

      default:
        reactPhoneInputDefaultCountry = defaultCountry.toLowerCase();
        break;
    }

    return (
      <ReactPhoneInput
        value={consumerDetails.phone}
        onChange={phoneHandleChange}
        component={TextField}
        countryCodeEditable={true}
        disableAreaCodes={true}
        defaultCountry={reactPhoneInputDefaultCountry}
        containerClass={addPhoneErrorClass()}
        inputExtraProps={phoneInputExtraProps}
        onBlur={phoneOnBlur}
      />
    );
  };

  const step1 = [
    {
      label: movingFromLabel === null ? `What ${zipNoun} are you moving from?` : movingFromLabel,
      name: 'originZip',
    },
    {
      label: movingToLabel === null ? `What ${zipNoun} are you moving to?` : movingToLabel,
      name: 'destinationZip',
    },
    {
      label: expectedMoveDateLabel,
      name: 'moveDate',
      customElement: renderDatePicker(),
    },
  ];

  const step2 = [
    {
      label: bedroomsLabel,
      name: 'beds',
      customElement: renderBedroomBoxes(),
    },
  ];

  const step3 = [
    {
      label: contactFormLabel,
      name: 'givenName',
      placeholder: firstNameLabel,
    },
    {
      name: 'familyName',
      placeholder: lastNameLabel,
    },
    {
      name: 'phone',
      customElement: renderPhoneInput(),
    },
    {
      name: 'email',
      placeholder: emailLabel,
    },
  ];

  const setCurrentStep = (modalStep) => {
    switch (modalStep) {
      case 'zip':
        return step1;
      case 'beds':
        return step2;
      case 'contact':
        return step3;
      default:
        return;
    }
  };

  const consentTextDefault = `By clicking "Continue", you consent for ${companyName} and partners to
  use automated technology, including pre-recorded messages, emails,
  cell phones and texts, to contact you at the number provided. This
  includes if the number is currently on any Do Not Call Lists. This
  consent is not required to make a purchase.`;

  const consentParagraph = consent === null ? consentTextDefault : consent;

  return (
    <form className='form' onSubmit={handleSubmit} noValidate>
      {setCurrentStep(modalStep).map((field, index) => {
        const { name, label, customElement, customHandleChange, inputType, placeholder } = field;
        const fieldError = formErrors[modalStep][name];
        const showError = didSubmit && fieldError;
        inputRefs[name] = React.createRef(); // create a ref for each input
        return (
          <div className={`form-field form-field-${modalStep}`} key={`${index}-${name}`} ref={inputRefs[name]}>
            {label && <label className='form-field-label'>{label}</label>}
            {customElement || (
              <TextField
                value={consumerDetails[name]}
                onChange={customHandleChange || handleChange}
                name={name}
                error={!!showError}
                placeholder={placeholder}
                type={inputType}
                inputProps={{
                  onFocus: () => handleInputFocus(name),
                }}
              />
            )}
            {<span className={`form-error-message error-${name} show-${showError}`}>{fieldError}</span>}
          </div>
        );
      })}
      {modalStep === 'contact' && (
        <div className='step-contact-legal'>
          <div className='privacy'>
            <Lock />
            <p>{securityText}</p>
          </div>
          <p className='consent'>{consentParagraph}</p>
        </div>
      )}
      <div className='button-wrapper'>
        <button
          id={'form-continue-button'}
          type='submit'
          onClick={implementCustomScript(modalStep)}
          disabled={isLoading}
          className={`continue-${modalStep}`} // classNames used for Analytics - beware of changing
        >
          {(isLoading) ? loading : continueButtonText /* prettier-ignore */}
        </button>
      </div>
    </form>
  );
};

export default ValidatedForm;
