import React, { useState, useEffect } from 'react';

import StartRecording from './StartRecording/StartRecording';
import SetReminder from './SetReminder/SetReminder';

import {
  printPhoneE164,
  isMobileDevice,
  setApiError,
  yemboApiCall,
  checkShouldSendSms,
  assignIdLogRocket,
  handleRecordingSubmit,
  handleReminderSubmit,
} from '../../../utils';
import { errorBackButton, branch, frontendUrl, errorGeneric } from '../../../../customization/_import';
import StartRecordingSuccess from './StartRecordingSuccess/StartRecordingSuccess';

const StartRecordingWrap = (props) => {
  const {
    setModalStep,
    consumerDetails: { destinationZip, originZip, beds, moveDate },
  } = props;

  let {
    // using let because I redefine these later
    familyName,
    givenName,
    email,
    phone,
  } = props.consumerDetails;

  // white spaces only possible on these fields
  familyName = familyName.trim();
  givenName = givenName.trim();
  email = email.trim();

  const [isMobile, setIsMobile] 							= useState(false); // prettier-ignore
  const [canSendSms, setCanSendSms] 						= useState(false); // prettier-ignore
  const [content, setContent] 							= useState('startRecording'); // prettier-ignore
  const [requestError, setRequestError] 					= useState({
		hasError: false,
		errorMessage: null,
		errorType: null,
	}); // prettier-ignore
  const { hasError, errorMessage, errorType } 					= requestError; // prettier-ignore
  const [isReminderLoading, setIsReminderLoading] 			= useState(false); // prettier-ignore
  const [didReminderSucceed, setDidReminderSucceed]				= useState(false); // prettier-ignore
  const [isSurveyLoading, setIsSurveyLoading] 				= useState(false); // prettier-ignore

  // removes all non-digit characters, adds plus sign in front. Formatted so validate-phone api call can read it properly.
  phone = phone ? printPhoneE164(phone) : null; // phone is not always required, so make sure it's there before calling print phone function

  useEffect(() => {
    setIsMobile(() => isMobileDevice());
    checkShouldSendSms(phone, setCanSendSms);
  }, [phone]);

  const sharedInviteData = {
    branch,
    email,
    familyName,
    givenName,
    link: frontendUrl,
    phone,
    shouldSendEmail: true,
  };

  const consumer = {
    familyName,
    givenName,
    email,
    phone,
  };

  const getInitialParams = async () => {
    const data = {
      origin: {
        zip: originZip,
        beds,
      },
      destination: {
        zip: destinationZip,
      },
      consumer,
      move: {
        dateKnown: true,
        date: moveDate.format('YYYY-MM-DD'),
        sourceSubtype: (isMobile) ? 'mobile' : 'desktop' // prettier-ignore
      },
    };
    try {
      const initialParamsRes = await yemboApiCall({
        method: 'post',
        url: 'initial-params',
        data,
      });
      return initialParamsRes.data;
    } catch (e) {
      setApiError(e, setRequestError);
    }
  };

  const prepareInviteData = async (reminderTime = null) => {
    const shouldSendSms = reminderTime ? false : canSendSms;
    const initialParams = await getInitialParams();
    const { identifier, moveKey } = initialParams;
    assignIdLogRocket(identifier, moveKey, consumer); // can only track a user after an identifier is created
    const inviteData = {
      ...sharedInviteData,
      identifier,
      moveKey,
      shouldSendSms,
      sendReminderAt: reminderTime,
    };
    return inviteData;
  };

  const recordingSubmit = () =>
    handleRecordingSubmit({
      setIsSurveyLoading,
      prepareInviteData,
      successAction: () => setContent('startRecordingSuccess'),
      setRequestError,
    });

  const reminderSubmit = (reminderTime) =>
    handleReminderSubmit({
      reminderTime,
      setIsReminderLoading,
      prepareInviteData,
      setDidReminderSucceed,
      successAction: () => setContent('startRecording'),
      setRequestError,
    });

  const renderContent = (name) => {
    // determines the page/view within the modal
    let content;
    switch (name) {
      case 'startRecording':
        content = (
          <StartRecording
            isSurveyLoading={isSurveyLoading}
            givenName={givenName}
            handleStartRecording={recordingSubmit}
            handleNotHome={() => setContent('setReminder')}
            email={email}
          />
        );
        break;
      case 'startRecordingSuccess':
        content = <StartRecordingSuccess canSendSms={canSendSms} />;
        break;
      case 'setReminder':
        content = (
          <SetReminder
            handleReminderSubmit={reminderSubmit}
            isReminderLoading={isReminderLoading}
            didReminderSucceed={didReminderSucceed}
            backButtonOnClick={() => setContent('startRecording')}
          />
        );
        break;
      default:
        content = null;
    }
    return content;
  };

  const renderError = () => {
    const message = errorMessage || errorGeneric;
    const isInvalidInput = errorType === 'invalid_input';
    const returnToContactForm = () => setModalStep('contact');

    return (
      <div className='error-message'>
        {message}
        {isInvalidInput && (
          <div className='error-back-button'>
            <button onClick={returnToContactForm}>{errorBackButton}</button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='form demo-page'>{(hasError) ? renderError() : renderContent(content) /* prettier-ignore */}</div>
  );
};

export default StartRecordingWrap;
