import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  recordingSubtitle,
  reminderConfirm,
  reminderSuccess,
  reminderSuccess2,
  reminderButtonText,
  backButton,
} from '../../../../../customization/_import';

const SetReminder = ({ handleReminderSubmit, didReminderSucceed, isReminderLoading, backButtonOnClick }) => {
  const isBefore6pm = moment().hours() < 18;
  const timeAt6pmToday = moment().hour(18).minutes(0);

  const timeAt6pmTomorrow = moment().add(1, 'days').hour(18).minutes(0);

  const defaultReminderTime = (isBefore6pm) ? timeAt6pmToday : timeAt6pmTomorrow; // prettier-ignore

  const [reminderTime, setReminderTime] = useState(defaultReminderTime);
  const [reminderError, setReminderError] = useState(false);

  const handleDateChange = (time) => {
    // Only lets a user choose a reminder time in the future
    const isFuture = moment().diff(time) < 0;
    const isReminderError = !isFuture;
    setReminderError(isReminderError);
    setReminderTime(time);
  };

  const formatReminderTime = () => {
    const fiveMinutesFromNow = moment().add(5, 'minutes');
    const isWithinFiveMinutes = moment(fiveMinutesFromNow).diff(reminderTime) > 0;
    let formattedTime = reminderTime;

    if (isWithinFiveMinutes) {
      // silently round to 5 minutes from now so it will be included in next api chronjob
      formattedTime = fiveMinutesFromNow;
    }
    return formattedTime.toISOString();
  };

  const renderTitle = () => <h3>{reminderButtonText}</h3>;

  const renderSubtitle = () => <p>{recordingSubtitle}</p>;

  const renderDatePicker = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        value={reminderTime}
        onChange={handleDateChange}
        disablePast // this only disables past for dates. HandleDateChange checks for past in time picker as well.
        minutesStep={5}
      />
    </MuiPickersUtilsProvider>
  );

  const renderError = () => reminderError && <p className='reminder-error'>Reminder time must be in the future.</p>;

  const renderReminderButton = () => (
    <div className='reminder-button-wrap'>
      <button
        onClick={() => handleReminderSubmit(formatReminderTime())}
        disabled={didReminderSucceed || reminderError}
        className='set-reminder-button' // className used for Analytics - beware of changing
      >
        <AccessAlarmIcon /> {(isReminderLoading) ? 'Loading' : reminderConfirm /* prettier-ignore */}
      </button>
    </div>
  );

  const renderSuccessMessage = () =>
    didReminderSucceed && (
      <div className='reminder-success-wrap'>
        <CheckCircleOutlineIcon className='reminder-success-icon' />
        <span>
          {reminderSuccess} {moment(reminderTime).format('MMMM Do, h:mm a')}
        </span>
        <span>{reminderSuccess2}</span>
      </div>
    );

  const renderBackButton = () =>
		(backButtonOnClick) && (
			<div className='reminder-back-button'>
				<button onClick={backButtonOnClick}>{backButton}</button>
			</div>
		); // prettier-ignore

  const renderSetReminder = () => (
    <div className='set-reminder'>
      {renderTitle()}
      {renderSubtitle()}
      {renderDatePicker()}
      {renderError()}
      {renderReminderButton()}
      {renderBackButton()}
    </div>
  );

  return (didReminderSucceed) ? renderSuccessMessage() : renderSetReminder(); // prettier-ignore
};

export default SetReminder;
