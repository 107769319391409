import React from 'react';

import star from '../../../../assets/testimonial/full-star.png';
import emptyStar from '../../../../assets/testimonial/empty-star.png';
import halfStar from '../../../../assets/testimonial/half-star.png';
import quote from '../../../../assets/testimonial/quote.png';

import {
  reviewQuote,
  reviewCaption,
  reviewStarsNumber,
  reviewStarsTotal,
  ratingCount,
  reviewText,
  reviewsUrl,
  testimonialSrc,
  testimonialSection,
  testimonialContent,
  moreReviews,
} from '../../../../customization/_import';

const Testimonial = () => {
  const noReviewSection = !reviewsUrl && !ratingCount && !reviewStarsNumber;

  const renderCustomTestionialContent = () => {
    const path = testimonialContent;
    const Component = require(`../../../../customization${path}`);
    return Component.default();
  };

  const findQuoteClassName = () => {
    if (noReviewSection) return 'quote-section only-quote-section';
    return 'quote-section';
  };

  const renderCouplePackingImage = () => (
    <div className='testimonial-image' style={{ backgroundImage: `url(${testimonialSrc})` }}>
      <img src={testimonialSrc} alt='couple-packing' />
    </div>
  );

  const renderTestimonialQuote = () => (
    <div className={findQuoteClassName()}>
      <img src={quote} alt='quote' />
      <p>{reviewQuote}</p>
      <p>{reviewCaption}</p>
    </div>
  );

  const starFilledImg = <img src={star} alt='star' />;

  const halfFilledImg = <img src={halfStar} alt='empty-star' />;

  const starEmptyImg = <img src={emptyStar} alt='empty-star' />;

  const isRatingScoreInteger = reviewStarsNumber % 1 === 0;

  const fullStarQuantity = Math.floor(reviewStarsNumber);
  const halfStarQuantity = isRatingScoreInteger ? 0 : 1;
  const emptyStarQuantity = reviewStarsTotal - (fullStarQuantity + halfStarQuantity);

  const makeStarListByType = (numStars, starImg) => {
    const starsArr = [];
    while (numStars > 0) {
      starsArr.push(starImg);
      numStars--;
    }
    return starsArr;
  };

  const filledStarList = makeStarListByType(Math.floor(fullStarQuantity), starFilledImg);

  const halfStarList = makeStarListByType(Math.floor(halfStarQuantity), halfFilledImg);

  const emptyStarList = makeStarListByType(emptyStarQuantity, starEmptyImg);

  const starReviewList = [...filledStarList, ...halfStarList, ...emptyStarList];

  const renderReviewStars = () => {
    if (reviewStarsNumber) {
      return (
        <div className='stars'>
          {starReviewList.map((star, index) => (
            <span key={`star-${index}`}>{star}</span>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderNumberOfReviews = () => {
    if (ratingCount) {
      return <p className='reviews-subtext'>{`(${ratingCount} reviews)`}</p>;
    }
    return null;
  };

  const renderReviewText = () => {
    if (reviewText) {
      return <p>{reviewText}</p>;
    } else if (reviewStarsNumber) {
      return <p>{`Our customers love us with ${reviewStarsNumber} out of ${reviewStarsTotal} stars!`}</p>;
    }
  };

  const renderMoreReviewsLink = () => {
    if (reviewsUrl) {
      return (
        <a className='reviews-subtext' href={reviewsUrl} target='_blank' rel='noopener noreferrer'>
          {moreReviews}
        </a>
      );
    }
    return null;
  };

  const getReviewClassName = () => {
    if ((!reviewsUrl || !ratingCount) && !noReviewSection) {
      // if there is review information but not all review info, add extra padding so it looks better
      return 'reviews extra-padding';
    }
    return 'reviews';
  };

  const renderTestimonialContent = () => {
    if (testimonialContent) {
      return renderCustomTestionialContent();
    }
    return (
      <div className='testimonial-content'>
        {renderTestimonialQuote()}
        <div className='review-wrapper'>
          {renderReviewStars()}
          <div className={getReviewClassName()}>
            {renderNumberOfReviews()}
            {renderReviewText()}
            {renderMoreReviewsLink()}
          </div>
        </div>
      </div>
    );
  };

  const renderTestimonialSection = () => {
    if (testimonialSection) {
      const path = testimonialSection;
      const Component = require(`../../../../customization${path}`);
      return Component.default();
    }
    return (
      <section className='testimonial'>
        {renderCouplePackingImage()}
        {renderTestimonialContent()}
      </section>
    );
  };

  return renderTestimonialSection();
};

export default Testimonial;
