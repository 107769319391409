import React from 'react';

import { ReactComponent as Step1Count } from '../../../../assets/steps/step-count-1.svg';
import { ReactComponent as Step2Count } from '../../../../assets/steps/step-count-2.svg';
import { ReactComponent as Step3Count } from '../../../../assets/steps/step-count-3.svg';

import {
  stepsTitle,
  step1Text,
  step2Text,
  step3Text,
  step1ImageSrc,
  step2ImageSrc,
  step3ImageSrc,
} from '../../../../customization/_import';

const Steps = () => {
  const steps = [
    {
      key: 1,
      image: step1ImageSrc,
      stepCount: <Step1Count className='step-svg' />,
      alt: 'record-video',
      description: step1Text,
    },
    {
      key: 2,
      image: step2ImageSrc,
      stepCount: <Step2Count className='step-svg' />,
      alt: 'itemized-inventory',
      description: step2Text,
    },
    {
      key: 3,
      image: step3ImageSrc,
      stepCount: <Step3Count className='step-svg' />,
      alt: 'quote',
      description: step3Text,
    },
  ];

  const renderStepsTitle = () => <h2>{stepsTitle}</h2>;

  const renderStepsList = () => (
    <div className='three-step-wrap'>
      {steps.map((step) => {
        const { key, image, stepCount, alt, description } = step;
        return (
          <div className='step' key={key}>
            <div className='step-image-wrapper'>
              <img className='step-image' src={image} alt={alt} />
              <div className='step-image-number'>{stepCount}</div>
            </div>
            <p>{description}</p>
          </div>
        );
      })}
    </div>
  );

  return (
    <section className='get-started'>
      {renderStepsTitle()}
      {renderStepsList()}
    </section>
  );
};

export default Steps;
