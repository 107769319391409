module.exports = {
	image_logoFavicon: "favicon.png",
	image_logo: "logo-rectangular.png",
	altCtaPhoneE164: "+17089490499",
	reviewCaption: "- Jurgen",
	reviewQuote: "I have only positives to say about this exceptional, local moving company. Zoomalo not only gave me a highly competitive pricing, but impeccable, friendly, no custom-complications service.",
	reviewStarsNumber: "5",
	//ratingCount: 225,
	certifications: null,
	reviewsUrl:
    'https://www.zoomalo.com/testimonials',
	companyLegalName: "Zoomalo Moving & Storage"
}