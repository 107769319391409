import React from 'react';

import ValidatedForm from './Steps/ValidatedForm/ValidatedForm';
import StartRecordingWrap from './Steps/StartRecording/StartRecordingWrap';
import ProgressBar from './ProgressBar/ProgressBar';
import { badLead } from '../../customization/_import';

const QuoteModal = (props) => {
  const { modalStep, isModalOpen, setModalStep, setIsModalOpen } = props;

  const renderProgressBar = () => (
    <ProgressBar modalStep={modalStep} setModalStep={setModalStep} setIsModalOpen={setIsModalOpen} />
  );

  const renderModalContent = (step) => {
    let content;
    switch (step) {
      case 'zip':
      case 'beds':
      case 'contact':
        content = <ValidatedForm {...props} />;
        break;
      //valid lead
      case 'startRecording':
        content = <StartRecordingWrap {...props} />;
        break;
      //invalid lead
      case 'badLead':
        content = <div className='bad-lead'>{badLead}</div>;
        break;
      default:
        content = null;
    }
    return <div className='modal-step'>{content}</div>;
  };

  return isModalOpen ? (
    <div className='modal-wrap'>
      <div className='modal'>
        {renderProgressBar()}
        {renderModalContent(modalStep)}
      </div>
    </div>
  ) : null;
};

export default QuoteModal;
