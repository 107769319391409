import React from 'react';
import {
  videoTipsStep1Text,
  videoTipsStep2Text,
  videoTipsStep3Text,
  videoTipsStep4Text,
  customIntegrationStepsHeader,
} from '../../../../../customization/_import';

const StartRecordingSteps = () => {
  const shouldGetCustomStep = videoTipsStep4Text !== null;

  const steps = [
    {
      key: 1,
      stepNumber: '1',
      stepText: videoTipsStep1Text,
    },
    {
      key: 2,
      stepNumber: '2',
      stepText: videoTipsStep2Text,
    },
    {
      key: 3,
      stepNumber: '3',
      stepText: videoTipsStep3Text,
    },
  ];

  const renderSteps = () => {
    if (shouldGetCustomStep) {
      steps.push({ key: 4, stepNumber: '4', stepText: videoTipsStep4Text });
    }

    return steps.map((step) => (
      <div className='step' key={step.key}>
        <div className='step-number'>{step.stepNumber}</div>
        <div className='step-text'>
          <h6>{step.stepText}</h6>
        </div>
      </div>
    ));
  };

  return (
    <div className='step-wrap'>
      <h5>{customIntegrationStepsHeader}</h5>
      <div className='step-list'>{renderSteps()}</div>
    </div>
  );
};

export default StartRecordingSteps;
