import React from 'react';
import { ArrowRight } from '@material-ui/icons';
import { ctaText } from '../../../customization/_import';

const QuoteButton = ({ onClick }) => {
  const getClassName = () => (ctaText) ? 'custom-text' : 'default-cta-text'; // prettier-ignore

  return (
    // button id used for Analytics - beware of changing
    <button id='show-quote-modal' className={getClassName()} onClick={onClick}>
      {
        ctaText || null // shows custom cta text if there - otherwise text is styled in CSS via className
      }
      <ArrowRight className='quote-arrow' />
    </button>
  );
};

export default QuoteButton;
