import React from 'react';
import moment from 'moment';
import {
  logoFooter,
  tagline,
  companyLegalName,
  certifications,
  privacyPolicyUrl,
  certificationImg,
  termsOfUse,
  privacyPolicy,
  certificationComponent,
} from '../../../../customization/_import';

const Footer = () => {
  const currentYear = moment().format('YYYY');

  const renderLogo = () => <img className='footer-logo' src={logoFooter} alt='company-logo' />;

  const renderTagLine = () => <h3>{tagline}</h3>;

  const renderTermsOfUse = () => {
    return (
      <p>
        {termsOfUse ||
          `The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and ${companyLegalName} regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.`}
      </p>
    );
  };

  const getCustomCertification = () => {
    const path = certificationComponent;
    const Component = require(`../../../../customization${path}`);
    return Component.default();
  };

  const renderCertifications = () => {
    const className = (certificationImg) ? 'cert-text-with-img' : 'cert-text'; // prettier-ignore
    const certification = certificationComponent ? getCustomCertification() : <p>{certifications}</p>;

    return (
      <div className='certifications'>
        {certificationImg && (
          <div className='copyright-img-wrap'>
            <img className='copyright-img' src={certificationImg} alt='copyright-img' />
          </div>
        )}
        <div className={className}>{certification}</div>
      </div>
    );
  };

  const renderCopyright = () => <p>{`© ${currentYear} ${companyLegalName}`}</p>;

  const renderPrivacyPolicy = () => (
    <a target='_blank' rel='noopener noreferrer' href={privacyPolicyUrl}>
      {privacyPolicy}
    </a>
  );

  return (
    <div className='footer-wrap'>
      <div className='footer'>
        {renderLogo()}
        {renderTagLine()}
        <div className='fine-print'>
          {renderTermsOfUse()}
          {renderCertifications()}
          {renderCopyright()}
          {renderPrivacyPolicy()}
        </div>
      </div>
    </div>
  );
};

export default Footer;
