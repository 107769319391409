import React, { useState, useEffect } from 'react';
import StartRecording from '../Modal/Steps/StartRecording/StartRecording/StartRecording';
import {
  setApiError,
  yemboApiCall,
  checkShouldSendSms,
  assignIdLogRocket,
  handleRecordingSubmit,
  handleReminderSubmit,
} from '../utils';
import { logoHeader, branch, frontendUrl, logoHeaderRedirect, errorGeneric } from '../../customization/_import';
import { Close } from '@material-ui/icons';
import StartRecordingSuccess from '../Modal/Steps/StartRecording/StartRecordingSuccess/StartRecordingSuccess';
import SetReminder from '../Modal/Steps/StartRecording/SetReminder/SetReminder';

const LandingPageRedirect = ({ identifier }) => {
  const [initialParams, setInitialParams] 				= useState(null); // prettier-ignore
  const [requestError, setRequestError] 					= useState({
		hasError: false,
		errorMessage: null,
		errorType: null,
	}); // prettier-ignore
  const { hasError, errorMessage } 						= requestError; // prettier-ignore
  const [canSendSms, setCanSendSms] 						= useState(null); // prettier-ignore
  const [givenName, setGivenName] 						= useState(null); // prettier-ignore
  const [initialLoading, setInitialLoading] 				= useState(true); // prettier-ignore
  const [isSurveyLoading, setIsSurveyLoading] 			= useState(false); // prettier-ignore
  const [isReminderLoading, setIsReminderLoading] 		= useState(false); // prettier-ignore
  const [didReminderSucceed, setDidReminderSucceed]		= useState(false); // prettier-ignore
  const [isRecordModalOpen, setIsRecordModalOpen] 		= useState(false); // prettier-ignore
  const [isReminderModalOpen, setIsReminderModalOpen] 	= useState(false); // prettier-ignore

  useEffect(() => {
    getInitialParamsFromUrl(identifier);
  }, [identifier]);

  useEffect(() => {
    if (initialParams) {
      const { identifier, moveKey } = initialParams;
      const { consumer } = initialParams.parameters;
      const { givenName, phone } = consumer;
      assignIdLogRocket(identifier, moveKey, consumer);
      setGivenName(givenName);
      checkShouldSendSms(phone, setCanSendSms); // check if number is textable
      setInitialLoading(false);
    }
  }, [initialParams]);

  const getInitialParamsFromUrl = async (identifier) => {
    try {
      const paramsFromUrl = await yemboApiCall({
        method: 'get',
        url: `initial-params?identifier=${identifier}`,
        data: {},
      });
      const { initialParams } = paramsFromUrl.data;
      setInitialParams(initialParams);
    } catch (e) {
      setApiError(e, setRequestError);
    }
  };

  const prepareInviteData = async (reminderTime = null) => {
    const shouldSendSms = reminderTime ? false : canSendSms;
    const { identifier, moveKey } = initialParams;
    const { familyName, givenName, phone, email } = initialParams.parameters.consumer; // need rest of the info from initialParams get request (from the URL)
    const inviteData = {
      branch,
      email,
      familyName,
      givenName,
      identifier,
      link: frontendUrl,
      phone,
      moveKey,
      shouldSendSms,
      shouldSendEmail: Boolean(email),
      sendReminderAt: reminderTime,
    };
    return inviteData;
  };

  const recordingSubmit = () =>
    handleRecordingSubmit({
      setIsSurveyLoading,
      prepareInviteData,
      successAction: () => setIsRecordModalOpen(true),
      setRequestError,
    });

  const reminderSubmit = (reminderTime) =>
    handleReminderSubmit({
      reminderTime,
      setIsReminderLoading,
      prepareInviteData,
      setDidReminderSucceed,
      successAction: () => setIsReminderModalOpen(false),
      setRequestError,
    });

  const renderLogoBar = () => (
    <div className='logo-bar'>
      <img src={logoHeaderRedirect || logoHeader} alt='logo' />
    </div>
  );

  const renderError = () => (
    <div className='start-recording-card'>
      <div className='error-message'>{errorMessage || errorGeneric}</div>
    </div>
  );

  const renderStartRecording = () => {
    if (!initialLoading) {
      return (
        <div className='start-recording-card'>
          <StartRecording
            isSurveyLoading={isSurveyLoading}
            givenName={givenName}
            handleNotHome={() => setIsReminderModalOpen(true)}
            handleStartRecording={recordingSubmit}
            identifier={identifier}
            email={initialParams.parameters.consumer.email}
          />
        </div>
      );
    }
  };

  const renderInviteSuccessModal = () =>
    isRecordModalOpen && (
      <div className='modal-wrap'>
        <div className='invite-success-modal'>
          <Close className='close-icon' onClick={() => setIsRecordModalOpen(false)} />
          <StartRecordingSuccess canSendSms={canSendSms} />
        </div>
      </div>
    );

  const renderReminderModal = () =>
    isReminderModalOpen && (
      <div className='modal-wrap'>
        <div className='reminder-modal'>
          <Close className='close-icon' onClick={() => setIsReminderModalOpen(false)} />
          <SetReminder
            handleReminderSubmit={reminderSubmit}
            isReminderLoading={isReminderLoading}
            didReminderSucceed={didReminderSucceed}
            backButtonOnClick={null}
          />
        </div>
      </div>
    );

  return (
    <div className='redirect-lander-wrap'>
      {renderLogoBar()}
      {(hasError) ? renderError() : renderStartRecording() /* prettier-ignore */}
      {renderInviteSuccessModal()}
      {renderReminderModal()}
    </div>
  );
};

export default LandingPageRedirect;
