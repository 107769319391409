import React from 'react';

import smiley from '../../../../assets/our-process/happy.png';
import {
  process1Text,
  process1Icon,
  process2Text,
  process2Icon,
  process3Text,
  process3Icon,
  processIcons,
  ourProcessSrc,
  experienceSmileysTag,
  listTitleText,
} from '../../../../customization/_import';

const Process = () => {
  const list = [
    {
      icon: process1Icon,
      alt: 'quick',
      text: process1Text,
    },
    {
      icon: process2Icon,
      alt: 'accurate',
      text: process2Text,
    },
    {
      icon: process3Icon,
      alt: 'video',
      text: process3Text,
    },
  ];

  const renderCoupleMovingImage = () => (
    <div className='our-process-image' style={{ backgroundImage: `url(${ourProcessSrc})` }}>
      <img src={ourProcessSrc} alt='couple-moving' />
    </div>
  );

  const renderListTitle = () => <h2>{listTitleText}</h2>;

  const renderList = () => (
    <ul>
      {list.map((item, index) => {
        const { icon, alt, text } = item;
        return (
          <li className={buildListItemClass(text)} key={`list-${index}`}>
            <img src={icon} alt={alt} />
            <div className={'list-item-text'}>{text}</div>
          </li>
        );
      })}
    </ul>
  );

  const buildListItemClass = (text) => (text ? 'list-item' : 'no-display');

  const getCustomProcessIcons = () => {
    const path = processIcons;
    const Component = require(`../../../../customization${path}`);
    return Component.default();
  };

  const renderExperienceSmileys = () => (
    <div className='experience'>
      <span>
        {experienceSmileysTag}
        {
          (processIcons) ? (
					getCustomProcessIcons()
				) : (
						<>
							<img src={smiley} alt='happy-face' />
							<img
								src={smiley}
								className='optional-icon'
								alt='happy-face'
							/>
							<img
								src={smiley}
								className='optional-icon'
								alt='happy-face'
							/>
						</>
					) // prettier-ignore
        }
      </span>
    </div>
  );

  return (
    <section>
      <div className='our-process'>
        {renderCoupleMovingImage()}
        <div className='our-process-text'>
          {renderListTitle()}
          {renderList()}
          {renderExperienceSmileys()}
        </div>
      </div>
    </section>
  );
};

export default Process;
