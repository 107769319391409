import React from 'react';
import smartphone from '../../../../../assets/modal/smartphone.png';
import {
  startRecordingSMS,
  startRecordingEmail,
  startRecordingText1,
  startRecordingText2,
} from '../../../../../customization/_import';

const StartRecordingSuccess = ({ canSendSms }) => {
  return (
    <div className='start-recording-success'>
      {/* prettier-ignore */}
      <h3>{(canSendSms) ? startRecordingSMS : startRecordingEmail}</h3>
      <img className='start-recording-success-image' src={smartphone} alt='smartphone' />
      <p>{startRecordingText1}</p>
      <p>{startRecordingText2}</p>
    </div>
  );
};

export default StartRecordingSuccess;
