import React from 'react';

import {
  otherMovers1Text,
  otherMovers1Icon,
  otherMovers2Text,
  otherMovers2Icon,
  otherMovers3Text,
  otherMovers3Icon,
  otherMoversSrcMobile,
  sadImgSrc,
} from '../../../../customization/_import';

const OtherMovers = () => {
  const item1 = otherMovers1Text && {
    icon: otherMovers1Icon,
    alt: 'quick',
    text: otherMovers1Text,
  };

  const item2 = otherMovers2Text && {
    icon: otherMovers2Icon,
    alt: 'accurate',
    text: otherMovers2Text,
  };

  const item3 = otherMovers3Text && {
    icon: otherMovers3Icon,
    alt: 'video',
    text: otherMovers3Text,
  };
  const list = [item1, item2, item3];

  const renderListTitle = () => <h2>Other Movers</h2>;

  const renderList = () => (
    <ul>
      {list.map((item, index) => {
        const { icon, alt, text } = item;
        return (
          <li className={buildListItemClass(text)} key={`list-${index}`}>
            <img src={icon} alt={alt} />
            <div className={'list-item-text'}>{text}</div>
          </li>
        );
      })}
    </ul>
  );

  const buildListItemClass = (text) => (text ? 'list-item' : 'no-display');

  const renderExperienceFrowns = () => (
    <div className='experience'>
      <span>
        Your Experience:
        <img src={sadImgSrc} alt='sad-face' />
        <img src={sadImgSrc} className='optional-sad' alt='sad-face' />
        <img src={sadImgSrc} className='optional-sad' alt='sad-face' />
      </span>
    </div>
  );
  // change all our-process things -- style other movers section
  const renderOtherMoversImage = () => (
    <div className='other-movers-image'>
      <img src={otherMoversSrcMobile} alt='other-movers' />
    </div>
  );

  return (
    <section>
      <div className='other-movers'>
        <div className='other-movers-text'>
          {renderListTitle()}
          {renderList()}
          {renderExperienceFrowns()}
        </div>
        {renderOtherMoversImage()}
      </div>
    </section>
  );
};

export default OtherMovers;
