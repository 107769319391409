import React from 'react';
import PageContainer from './react/PageContainer';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is. Needed to edit their rem values.
    htmlFontSize: 10,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <PageContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;
