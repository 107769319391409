import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
  tableNoneIcon,
  tableCheckIcon,
  tableHeaderOldWay,
  tableHeaderOurWay,
  rowName1,
  rowName2,
  rowName3,
  rowName4,
  rowName5,
} from '../../../../customization/_import';

const SummaryTable = () => {
  const getRowNames = () => {
    const shouldGetRowName5 = rowName5 !== null;
    const rowNamesArray = [rowName1, rowName2, rowName3, rowName4];

    if (shouldGetRowName5) {
      rowNamesArray.push(rowName5);
    }

    return rowNamesArray;
  };

  const rowNames = getRowNames();

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th width='40%'></th>
        <th width='30%' className='old-way'>
          {tableHeaderOldWay}
        </th>
        <th width='30%' className='our-way'>
          {tableHeaderOurWay}
        </th>
      </tr>
    </thead>
  );

  const renderTableRows = () => (
    <tbody>
      {rowNames.map((name, index) => (
        <tr key={`row-${index}`}>
          {renderRowName(name)}
          {renderNoneIcon()}
          {renderCheckIcon()}
        </tr>
      ))}
    </tbody>
  );

  const renderRowName = (name) => <td className='summary-name'>{name}</td>;

  const renderNoneIcon = () => (
    <td>
      <img className='table-icon' src={tableNoneIcon} alt='none' />
    </td>
  );

  const renderCheckIcon = () => {
    const checkIcon = (tableCheckIcon) ? (
			<img src={tableCheckIcon} alt='check' />
		) : (
			<CheckCircleIcon fontSize='large' className='check-icon' />
		); // prettier-ignore

    return (
      <td>
        <div className='table-icon'>{checkIcon}</div>
      </td>
    );
  };

  return (
    <section className='summary-table'>
      <table>
        {renderTableHeader()}
        {renderTableRows()}
      </table>
    </section>
  );
};

export default SummaryTable;
